/* Lora */
@font-face {
	font-family: "Lora";
	src:
		url("/fonts/Lora/Lora-Regular.woff2") format("woff2"),
		url("/fonts/Lora/Lora-Regular.woff") format("woff"),
		url("/fonts/Lora/Lora-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Lora";
	src:
		url("/fonts/Lora/Lora-Italic.woff2") format("woff2"),
		url("/fonts/Lora/Lora-Italic.woff") format("woff"),
		url("/fonts/Lora/Lora-Italic.ttf") format("truetype");
	font-style: italic;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Lora";
	src:
		url("/fonts/Lora/Lora-Bold.woff2") format("woff2"),
		url("/fonts/Lora/Lora-Bold.woff") format("woff"),
		url("/fonts/Lora/Lora-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "Lora";
	src:
		url("/fonts/Lora/Lora-BoldItalic.woff2") format("woff2"),
		url("/fonts/Lora/Lora-BoldItalic.woff") format("woff"),
		url("/fonts/Lora/Lora-BoldItalic.ttf") format("truetype");
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}

/* Regular */
@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-Regular.woff2") format("woff2"),
		url("/fonts/Regular/Regular-Regular.woff") format("woff"),
		url("/fonts/Regular/Regular-Regular.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-RegularItalic.woff2") format("woff2"),
		url("/fonts/Regular/Regular-RegularItalic.woff") format("woff"),
		url("/fonts/Regular/Regular-RegularItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-Medium.woff2") format("woff2"),
		url("/fonts/Regular/Regular-Medium.woff") format("woff"),
		url("/fonts/Regular/Regular-Medium.otf") format("opentype");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-MediumItalic.woff2") format("woff2"),
		url("/fonts/Regular/Regular-MediumItalic.woff") format("woff"),
		url("/fonts/Regular/Regular-MediumItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-Bold.woff2") format("woff2"),
		url("/fonts/Regular/Regular-Bold.woff") format("woff"),
		url("/fonts/Regular/Regular-Bold.otf") format("opentype");
	font-style: bold;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: "Regular";
	src:
		url("/fonts/Regular/Regular-BoldItalic.woff2") format("woff2"),
		url("/fonts/Regular/Regular-BoldItalic.woff") format("woff"),
		url("/fonts/Regular/Regular-BoldItalic.otf") format("opentype");
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}