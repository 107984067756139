#vendors-list-header {
	margin-top: 26px !important;
	margin-left: 32px !important;

	@media (max-width: 425px) {
		width: auto !important;
		margin: 26px 0 0 32px !important;
	}
}

.back-btn-handler {
	outline: 0 !important;

	.pc-back-button-text {
		position: relative !important;
		top: 1px !important;
		left: 2px !important;
		display: inline !important;
		font-size: 16px !important;
		color: #0f2f7f !important;
		transition: color 0.2s linear !important;
	}

	svg {
		transform: scale(1.2) !important;

		path {
			fill: #0f2f7f !important;
			transition: all 0.2s linear !important;
		}
	}

	&:hover {
		color: #4472ca !important;

		svg {
			path {
				fill: #4472ca !important;
			}
		}

		.pc-back-button-text {
			color: #4472ca !important;
			opacity: 1 !important;
		}
	}
}

#vendor-close-pc-btn-handler {
	position: relative !important;
	bottom: 6px !important;
	width: 24px !important;
	height: 24px !important;
	margin-top: 0 !important;
	margin-right: 22px !important;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAASKADAAQAAAABAAAASAAAAACQMUbvAAAEbUlEQVR4Ae3bXUgUQRwA8P/5EV2fWoplYvZBWZioEYWIBRLlQ2VFUNoH9PEkYYm9BJ19gBBEKPYSvfSS9RRBhIYvJRlSCYKmmUWiYmqmZ2UWflw3BwPH3u3Ox83MbjD7Mrc7N/P/78/xdnd21zU6PukDvZgKRJnW6IqAgAYiDAQNpIEIAoRqPYI0EEGAUK1HkAYiCBCquUfQd+8k9A9NELp3RjXK9cPnEZiemWVOKIa1RfO7XvBUN0LvwHigadwSN1w8nQfHi7JZu5L+/Y6eIbh88zm8/zQciBUTEwUlB7Lh0rl8cM+PpYrvYrnUaGj6CKWeJ2E7Lty1EWqu7IPoaO5BGbZf3o31L7uh7PpTmJ2dC+liR1YqPKg+GrI93AbqvZn6Mw2e243h+ghsq3/hT+hG+IRMG0mqsMJBIVva+uBxQwdVdGqgzp4RQP/LVosTkEg4OP9Xrb34o2VJDTQy9suyI1xpJxItDsr129hvnLJlSQ2Uvi7RsqPgSjuQWHBQrpsp94caaE3KMti5fW2wg+VnlUisOOgIVkJ51KUGQhpVFXsgKWGRJUxwpQokVhyUX2VZAaQmxwWnavqZCWhF4mKoqz7mGCQenKtlu+FIYaYpiLGCCQg1TkuJdwQSL86Jg2wntMxATkBShYP2lQvITiSVOBEB2YGkGidiIJVIduAIAVKBZBeOMCCZSHbiCAWSgWQ3jnAgkUhOwJECJALJKThoX5hmFFEDlgVNyxZfeAjDo3RTJajvrVtWQVvn17AzgWax0eUD6xmyWV/G7VKBUDAeJGOSVusycVBc7jNpq6SD63iu3YLbW32WjYNiSwdCQWQgqcBRBiQaSRWOUiBRSCpxlAMFIy2PW4hWmRbP+QJpRyuzRJT8BhmDd/lvA3t/Thk3E9fftg8wHf6JHVJ8QTkQz0kg3g8Vc9w4Fi6VAkWCgxNWjaQMSASOHUhKgETiqEaSDiQDRyWSVCAenNKTuY6574b+ENKAeHDQSWC5/2EsJ92clALEi4OnLHiu3WQd3YQDRYqDf1+cgiQUSBSOk5CEAYnGcQqSECBZOE5AihhINo7dSBEBqcKxE4kbSDWOXUhcQOiBcrOHtPGOGEuRM4GRnALMzbG9ossM1DfohYqqZ0wTVyJxMDwv0t26FtwFVckMdOteE6Cn7mkXGTg4Ng9Szf3X4P1BP5vJDNTWNYjzI5YycXBwViT0xk9H9xBuTiyZgWbCvBwSLooKHByXFenvdOgLLrgvY8kMlJWebOwjZF0lDg5Oi+RyuSBjQxJuRiyZgcrP5MG82GjTju3AwcnQIJ06nMM038QMtD4tAe5cKwLjfS30Yl1t5X7l960wDi4R0qPa4sBTIngbLg/tzYCKs/l4larkfrpjwn9fq7m1D3r7x2C1P6ncnFSIX7qAKqiKL/l8Pmh68wXa/T/IbncsbMtIgcxNK5lDcwMxR/pPGzD/i/2n+8mdtgYi0GkgDUQQIFTrEaSBCAKEaj2CNBBBgFCtR5AGIggQqv8BMsoVpr36xToAAAAASUVORK5CYII=') !important;
	transition: opacity 0.2s linear !important;
}

#vendor-list-content {
	overflow-y: auto !important;

	@media (max-width: 425px) {
		margin-left: 32px !important;
	}
}

#vendors-list-title {
	font-size: 21px !important;
	font-weight: 700 !important;
	color: #000 !important;
}

#search-container {
	background-color: transparent !important;

	& > svg {
		path {
			fill: #0f2f7f !important;
		}
	}

	#vendor-search-handler {
		border: solid 0.5px rgba(153, 153, 153, 0.6) !important;
		border-radius: 1px !important;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) !important;
		transition: border-color 0.2s linear;

		&:focus {
			border-color: #0f2f7f !important;
		}

		@media (max-width: 425px) {
			width: 55% !important;
			margin-left: 42px !important;
		}
	}

	#filter-btn-handler {
		background-color: #0f2f7f !important;
		border-radius: 1px !important;
		outline: 0 !important;
		transition: background-color 0.2s linear !important;

		&:hover {
			background-color: #4472ca !important;
		}
	}
}

#clear-filters-handler {
	margin-bottom: 16px !important;
	font-size: 13px !important;
	outline: 0 !important;

	& > p {
		font-weight: 700 !important;
		color: #0f2f7f !important;
		transition: color 0.2s linear !important;

		&:hover {
			color: #4472ca !important;
		}
	}
}

.ot-group-options {
	padding-bottom: 60px !important;
}

.ot-checkbox {
	label {
		&::before {
			border-radius: 0 !important;
			outline: 0 !important;
		}
	}

	span {
		position: relative !important;
		top: 1px !important;
		font-size: 16px !important;
		font-weight: 500 !important;
		line-height: 22px !important;
		color: #333;
	}
}

.ot-group-option {
	@media (max-width: 425px) {
		margin-bottom: 20px;
		margin-left: 15px;
	}
}

#filter-apply-handler {
	position: absolute !important;
	right: 10px !important;
	bottom: 0 !important;
	font-size: 12px !important;
	font-weight: 700 !important;
	text-transform: uppercase !important;
	background-color: #0f2f7f !important;
	outline: none !important;
	transition: background-color 0.2s linear !important;

	&:hover {
		background-color: #4472ca !important;
	}

	@media (max-width: 425px) {
		width: auto !important;
	}
}

.accordion-header {
	outline: 0 !important;
}

.host-item {
	width: 85% !important;
	padding: 12px 4px 12px 1px !important;
	border-bottom: 1px solid #d8d8d8 !important;
	border-radius: 0.5px !important;

	@media (min-width: 576px) {
		width: 100% !important;
	}

	.accordion-text {
		margin: 0 !important;
	}

	.host-notice {
		display: flex !important;
		align-items: center;
	}

	.host-title {
		font-size: 16px !important;
		font-weight: 700 !important;
		color: #333 !important;
		outline: 0 !important;

		a {
			font-size: 16px !important;
			font-weight: 700 !important;
			color: #333 !important;
			text-decoration: none !important;
			outline: 0 !important;
		}
	}

	.host-view-cookies {
		font-size: 13px !important;
		font-weight: 700 !important;
		color: #0f2f7f !important;
	}
}

.host-option-group {
	margin-top: 18px !important;

	.vendor-host {
		background-color: #e4e8f1 !important;
		border-radius: 1px !important;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12) !important;

		& > div > div:first-child {
			font-size: 13px !important;
			font-weight: 500 !important;
			color: #333 !important;
		}

		& > div > div:not(:first-child) {
			font-size: 13px !important;
			font-weight: 500 !important;
			color: #333 !important;

			a {
				font-size: 13px !important;
				font-weight: 700 !important;
				color: #0f2f7f !important;
				outline: 0 !important;
				transition: all 0.2s linear !important;

				&:hover {
					color: #4472ca !important;
				}
			}
		}
	}
}

#vendor-list-save-btn {
	margin-top: 46px !important;

	@media (max-width: 425px) {
		padding-left: 40px !important;
	}
}
