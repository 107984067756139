// Required
@import '~leaflet/dist/leaflet.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';

$shamrock-green: #00c16c;
$marigold: #ffc500;
$orange: #e67f0d;
$lipstick: #cb1723;
$twilight-blue: #0f2f7f;

.leaflet-container {
	width: 100%;
	height: 304px;

	@media (min-width: 576px) {
		height: 301px;
	}

	@media (min-width: 992px) {
		height: 492px;
	}

	@media (min-width: 1200px) {
		height: 587px;
	}
}

.leaflet-container.leaflet-container-mobile {
	z-index: 1;
	min-height: 100vh;

	@supports (-webkit-touch-callout: none) {
		body {
			/* stylelint-disable-next-line */
			min-height: -webkit-fill-available;
		}
	}
}

// Zoom buttons
.leaflet-bar {
	border-radius: 0;
	box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	background-clip: border-box;
	border: 0;
}

.leaflet-top .leaflet-control {
	margin-top: 13px;

	@media (min-width: 992px) {
		margin-top: 36px;
	}
}

.leaflet-right .leaflet-control {
	margin-right: 16px;

	@media (min-width: 992px) {
		margin-right: 24px;
	}
}

.leaflet-bar a,
.leaflet-bar a:hover {
	position: relative;
	width: 32px;
	height: 32px;
	overflow: hidden;
	line-height: 32px;
	text-indent: 100%;
	white-space: nowrap;
	border-bottom: 0;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
	transition: background-color 0.2s linear;
}

.leaflet-bar a:hover {
	background-color: #eceff5;
}

.leaflet-bar a:first-child {
	border-radius: 1.6px;

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 24px;
		height: 24px;
		pointer-events: none;
		content: ' ';
		background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjMyOCAxOS4xNzlWNW0tNy4zMjIgNy4zMzNoMTQuMTc5eiIgc3Ryb2tlPSIjMGYyZjdmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+');
		transform: translate(-50%, -50%);
	}
}

.leaflet-bar a:last-child {
	margin-top: 12px;
	border-radius: 1.6px;

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 24px;
		height: 24px;
		pointer-events: none;
		content: ' ';
		background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMTIuMDloMTQuMTc5IiBzdHJva2U9IiMwZjJmN2YiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4=');
		transform: translate(-50%, -50%);
	}
}

.leaflet-touch .leaflet-bar a {
	width: 32px;
	height: 32px;
	line-height: 32px;

	&:last-child {
		border-radius: 1.6px;
	}
}

// Bottom Attribution
.leaflet-control-attribution,
.leaflet-control-scale-line {
	color: #000;
}

.leaflet-container .leaflet-control-attribution {
	margin: 0;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-family: Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
	font-weight: 700;
}

/* stylelint-disable-next-line no-descending-specificity */
.leaflet-container a {
	color: $twilight-blue;
}

// Popup
.leaflet-popup {
	// ! fix not working popupAnchor in the Javascript
	margin-bottom: 28px;
	margin-left: 8px;
}

.leaflet-popup-content-wrapper {
	border-radius: 1.1px;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	color: #000;
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
}

.leaflet-popup-content {
	position: relative;
	margin: 19px 17px 0;

	@media (min-width: 992px) {
		margin: 21px 19px 0;
	}

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		content: '';
		background: linear-gradient(to top, white 0%, rgba(white, 0) 100%);
		background-repeat: no-repeat;
		background-position: bottom left;
		background-size: 100% 30px;
	}

	p {
		margin: 0;
	}
}

.leaflet-container a.leaflet-popup-close-button {
	top: 11px;
	right: 12px;
	width: 24px;
	height: 24px;
	padding: 0;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuMDM4IDQuNzY0TDE4Ljk2MiAxOC42OW0tMTMuNDU2LS4wMTFMMTkuNDMxIDQuNzUzIiBmaWxsPSJub25lIiBzdHJva2U9IiMwZjJmN2YiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L3N2Zz4=');
}

// Custom Popup
/* stylelint-disable-next-line no-duplicate-selectors */
.leaflet-popup-content-wrapper {
	max-width: 271px;
}

.leaflet-popup-content-wrapper__header {
	padding: 0 0 9px;
	margin: 0;
	border-bottom: 3px solid $twilight-blue;

	@media (min-width: 992px) {
		padding: 0 0 10px;
		margin: 0;
		border-bottom: 4px solid $twilight-blue;
	}
}

.leaflet-popup-content-wrapper__street-name {
	padding-right: 22px;
	margin: 0 0 2px !important; /* overwrite leaflet styles */
	font-family: Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: #000;

	@media (min-width: 992px) {
		margin: 0 0 3px !important; /* overwrite leaflet styles */
		font-size: 20px;
	}
}

.leaflet-popup-content-wrapper__traffic-status {
	position: relative;
	padding: 0 0 0 17px;
	margin: 0;
	font-family: Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	color: #000;
	text-transform: capitalize;

	@media (min-width: 992px) {
		font-size: 16px;
		line-height: 20px;
	}

	&::before {
		position: absolute;
		top: calc(50% - 1px);
		left: 0;
		width: 10px;
		height: 10px;
		content: ' ';
		background-color: $shamrock-green;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	&.fechado {
		&::before {
			background-color: $lipstick;
		}
	}

	&.condicionado {
		&::before {
			background-color: $marigold;
		}
	}

	&.intermitente {
		&::before {
			background-color: $orange;
		}
	}

	&.reaberto {
		&::before {
			background-color: $shamrock-green;
		}
	}
}

.leaflet-popup-content-wrapper__list {
	display: flex;
	flex-wrap: wrap;
	width: 206px;
	max-height: 188px;
	padding: 3px 0 0;
	margin: 0;
	overflow-y: auto;
	list-style: none;

	@media (min-width: 992px) {
		width: 230px;
	}

	&::after {
		display: block;
		width: 100%;
		padding-bottom: 20px;
		content: ' ';

		@media (min-width: 992px) {
			padding-bottom: 24px;
		}
	}
}

.leaflet-popup-content-wrapper__item {
	width: 50%;
	padding: 13px 0 12px;
	margin: 0;
	border-bottom: 1px solid #dcdde2;

	@media (min-width: 992px) {
		padding: 13px 0 11px;
	}

	&:last-child {
		padding: 13px 0 0;
		border-bottom: 0;

		@media (min-width: 992px) {
			padding: 12px 0 0;
		}
	}
}

.leaflet-popup-content-wrapper__item--full-width {
	width: 100%;
}

.leaflet-popup-content-wrapper__info-title {
	margin: 0 0 2px !important; /* overwrite leaflet styles */
	font-family: Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	color: #000;

	@media (min-width: 992px) {
		margin: 0 0 4px !important; /* overwrite leaflet styles */
		font-size: 16px;
		line-height: 20px;
	}
}

.leaflet-popup-content-wrapper__info-detail {
	margin: 0;
	font-family: Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	color: #000;

	@media (min-width: 992px) {
		font-size: 14px;
		line-height: 18px;
	}
}

// marker cluster styles
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(black, 0.12);
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
	background-color: $twilight-blue;
}

.marker-cluster {
	width: 36px !important;
	height: 36px !important;
	background-clip: padding-box;
	border-radius: 50%;
}

.marker-cluster div {
	width: 28px;
	height: 28px;
	padding-top: 2px;
	margin-top: 4px;
	margin-left: 4px;
	font-family: 'Regular', Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	text-align: center;
	border-radius: 15px;
}

.marker-cluster span {
	line-height: 28px;
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small,
.leaflet-oldie .marker-cluster-medium,
.leaflet-oldie .marker-cluster-large {
	background-color: #fff;
}

.leaflet-oldie .marker-cluster-small div,
.leaflet-oldie .marker-cluster-medium div,
.leaflet-oldie .marker-cluster-large div {
	background-color: $twilight-blue;
}

// hide layers
.hide_lyr_PTS_LOW .lyr_PTS_LOW,
.hide_lyr_PTS_HIGH .lyr_PTS_HIGH,
.hide_lyr_POLYS_LOW .lyr_POLYS_LOW,
.hide_lyr_POLYS_HIGH .lyr_POLYS_HIGH {
	display: none;
}

// mobile apps popup
#mobile-popup {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3;
	height: 319px;
	padding: 24px 24px 0;
	background: white;
	border-radius: 10px 10px 0 0;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
	transition: all 250ms ease;
	transform: translate3d(0, 0, 0);

	&:empty,
	&.hide {
		transform: translate3d(0, 100%, 0);
	}

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;
		content: '';
		background: linear-gradient(to top, white 0%, rgba(white, 0) 100%);
		background-repeat: no-repeat;
		background-position: bottom left;
		background-size: 100% 30px;
	}

	.leaflet-popup-content-wrapper {
		&__list {
			position: relative;
			width: 100%;
			max-height: 239px;

			&::after {
				padding-bottom: 30px;
			}
		}

		&__street-name {
			font-size: 20px;
		}

		&__traffic-status,
		&__info-title,
		&__info-detail {
			font-size: 16px;
			line-height: 19px;
		}
	}

	.leaflet-popup-close-button {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 24px;
		height: 24px;
		padding: 0;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMgMy4wMTRMMjAuNDA2IDIwLjQybS0xNi44Mi0uMDE0TDIwLjk5MSAzIiBzdHJva2U9IiMwRjJGN0YiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PC9zdmc+');
		background-repeat: no-repeat;
	}
}
