#onetrust-banner-sdk {
	overflow: hidden !important;
	font-family: inherit !important;
	background-color: #eceff5 !important;
	box-shadow: none !important;

	.onetrust-close-btn-handler {
		width: 14px !important;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAYAAABV7bNHAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAASKADAAQAAAABAAAASAAAAACQMUbvAAAEbUlEQVR4Ae3bXUgUQRwA8P/5EV2fWoplYvZBWZioEYWIBRLlQ2VFUNoH9PEkYYm9BJ19gBBEKPYSvfSS9RRBhIYvJRlSCYKmmUWiYmqmZ2UWflw3BwPH3u3Ox83MbjD7Mrc7N/P/78/xdnd21zU6PukDvZgKRJnW6IqAgAYiDAQNpIEIAoRqPYI0EEGAUK1HkAYiCBCquUfQd+8k9A9NELp3RjXK9cPnEZiemWVOKIa1RfO7XvBUN0LvwHigadwSN1w8nQfHi7JZu5L+/Y6eIbh88zm8/zQciBUTEwUlB7Lh0rl8cM+PpYrvYrnUaGj6CKWeJ2E7Lty1EWqu7IPoaO5BGbZf3o31L7uh7PpTmJ2dC+liR1YqPKg+GrI93AbqvZn6Mw2e243h+ghsq3/hT+hG+IRMG0mqsMJBIVva+uBxQwdVdGqgzp4RQP/LVosTkEg4OP9Xrb34o2VJDTQy9suyI1xpJxItDsr129hvnLJlSQ2Uvi7RsqPgSjuQWHBQrpsp94caaE3KMti5fW2wg+VnlUisOOgIVkJ51KUGQhpVFXsgKWGRJUxwpQokVhyUX2VZAaQmxwWnavqZCWhF4mKoqz7mGCQenKtlu+FIYaYpiLGCCQg1TkuJdwQSL86Jg2wntMxATkBShYP2lQvITiSVOBEB2YGkGidiIJVIduAIAVKBZBeOMCCZSHbiCAWSgWQ3jnAgkUhOwJECJALJKThoX5hmFFEDlgVNyxZfeAjDo3RTJajvrVtWQVvn17AzgWax0eUD6xmyWV/G7VKBUDAeJGOSVusycVBc7jNpq6SD63iu3YLbW32WjYNiSwdCQWQgqcBRBiQaSRWOUiBRSCpxlAMFIy2PW4hWmRbP+QJpRyuzRJT8BhmDd/lvA3t/Thk3E9fftg8wHf6JHVJ8QTkQz0kg3g8Vc9w4Fi6VAkWCgxNWjaQMSASOHUhKgETiqEaSDiQDRyWSVCAenNKTuY6574b+ENKAeHDQSWC5/2EsJ92clALEi4OnLHiu3WQd3YQDRYqDf1+cgiQUSBSOk5CEAYnGcQqSECBZOE5AihhINo7dSBEBqcKxE4kbSDWOXUhcQOiBcrOHtPGOGEuRM4GRnALMzbG9ossM1DfohYqqZ0wTVyJxMDwv0t26FtwFVckMdOteE6Cn7mkXGTg4Ng9Szf3X4P1BP5vJDNTWNYjzI5YycXBwViT0xk9H9xBuTiyZgWbCvBwSLooKHByXFenvdOgLLrgvY8kMlJWebOwjZF0lDg5Oi+RyuSBjQxJuRiyZgcrP5MG82GjTju3AwcnQIJ06nMM038QMtD4tAe5cKwLjfS30Yl1t5X7l960wDi4R0qPa4sBTIngbLg/tzYCKs/l4larkfrpjwn9fq7m1D3r7x2C1P6ncnFSIX7qAKqiKL/l8Pmh68wXa/T/IbncsbMtIgcxNK5lDcwMxR/pPGzD/i/2n+8mdtgYi0GkgDUQQIFTrEaSBCAKEaj2CNBBBgFCtR5AGIggQqv8BMsoVpr36xToAAAAASUVORK5CYII=') !important;
		transition: opacity 0.2s linear !important;
	}
}

#onetrust-policy {
	margin: 10px 0 10px 40px !important;

	&-text {
		padding-right: 12px !important;
		margin: 0 !important;
		font-size: 14px !important;
		font-weight: 500 !important;
		line-height: 1.43 !important;
		color: #333 !important;

		@media (min-width: 897px) {
			padding-top: 10px !important;
		}

		@media (min-width: 1024px) {
			padding-top: 0 !important;
		}

		a {
			margin-left: 2px !important;
			font-weight: 700 !important;
			color: #0f2f7f !important;
			text-decoration: underline !important;
			outline: 0;
			transition: color 0.2s linear !important;

			&:hover,
			&:focus,
			&:active {
				color: #4472ca !important;
			}
		}
	}
}

#onetrust-button-group {
	@media (min-width: 896px) {
		margin-top: 0 !important;
	}

	@media (max-width: 576px) {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	@media (max-width: 896px) {
		margin-right: 0 !important;
	}

	#onetrust-pc-btn-handler {
		width: 170px !important;
		padding: 12px 0 !important;
		font-size: 12px !important;
		font-weight: 700 !important;
		color: #0f2f7f !important;
		text-decoration: none !important;
		text-transform: uppercase !important;
		background: transparent !important;
		border: none !important;
		outline: none !important;
		transition: color 0.2s linear !important;

		@media (min-width: 896px) {
			margin-top: 12px !important;
			margin-bottom: 16px !important;
		}

		&:hover {
			color: #4472ca !important;
		}
	}

	#onetrust-accept-btn-handler {
		width: 170px !important;
		font-size: 12px !important;
		color: white !important;
		text-transform: uppercase !important;
		background-color: #0f2f7f !important;
		opacity: 1 !important;
		transition: background-color 0.2s linear !important;

		&:hover {
			background-color: #0a2058 !important;
		}
	}
}
